<template>
  <v-container
    fluid
    class="text-justify"
  >
    <v-row no-gutters justify="center">
      <v-col md="8">
        <h2 class="text-h6 text-center mb-5">
          VOKAR NRW - Naturschutzrechtliches Vorkaufsrecht
        </h2>

        <p>
          Die Anwendung VOKAR unterst&uuml;tzt Notare und Beh&ouml;rden die gesetzlichen Vorschriften zu
          Vorkaufsrechten nach &sect;&nbsp;74 Landesnaturschutzgesetz NRW (LNatSchG), die nicht im Grundbuch
          verzeichnet sind, zu vollziehen.
        </p>

        <p>
          Hierbei werden von VOKAR jede Einsichtnahme sowie rechtsverbindliche Ausk&uuml;nfte zu
          Vorkaufsrechten des Landes NRW f&uuml;r Notare aus dem gesamten Bundesgebiet erzeugt, gespeichert
          und zum Download zur Verf&uuml;gung gestellt.
        </p>

        <p>
          Datengrundlage bildet das amtliches Verzeichnis derjenigen Grundst&uuml;cke in Nordrhein-Westfalen,
          f&uuml;r die ein Vorkaufsrecht nach &sect;&nbsp;74 Landesnaturschutzgesetz NRW (LNatSchG) besteht.
        </p>

        <p>
          Die zust&auml;ndigen Beh&ouml;rden f&uuml;r die Wahrnehmung der genannten Vorkaufsrechte in NRW sind
          die Bezirksregierungen. Die das &ouml;ffentliche Verzeichnis f&uuml;hrende Beh&ouml;rde ist das
          Landesamt f&uuml;r Natur, Umwelt und Verbraucherschutz NRW (LANUV).
        </p>

        <v-row no-gutters class="justify-center">
          <v-col md="6">
            <v-btn
              v-for="doc in documents"
              block
              class="mb-2"
              color="grey lighten-2"
              depressed
              :href="doc.href"
              :key="doc.label"
              style="font-size: 13px"
              target="_blank"
              tile
            >
              {{ doc.label }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ROLE_BNOTK, ROLE_LV_FACHBETREUER, ROLE_LV_BEZIRKSREGIERUNG } from '../config';

export default {
  props: {
    user: {}
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    };
  },
  computed: {
    documents () {
      if (this.user.hasAnyRole([ROLE_LV_FACHBETREUER, ROLE_LV_BEZIRKSREGIERUNG])) {
        return this.docsLV;
      }
      if (this.user.hasAnyRole([ROLE_BNOTK])) {
        return this.docsNotar;
      }
      return [];
    },
    docsNotar () {
      return [
        {
          label: 'Benutzerhandbuch',
          href: `${this.publicPath}docs/VOKAR Benutzerhandbuch_Notar.pdf`
        },
        {
          label: 'GIS-Datengrundlage',
          href: `${this.publicPath}docs/VOKAR Datengrundlage-LNatSchG.pdf`
        }
      ];
    },
    docsLV () {
      return [
        {
          label: 'Benutzerhandbuch',
          href: `${this.publicPath}docs/VOKAR Benutzerhandbuch_BR.pdf`
        },
        {
          label: 'GIS-Datengrundlage',
          href: `${this.publicPath}docs/VOKAR Datengrundlage-LNatSchG.pdf`
        }
      ];
    }
  }
};
</script>
